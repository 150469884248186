.disclaimer {
  display: flex;
  align-items: flex-start;
  background: #e5f0ff;
  gap: 12px;
  padding: 8px;
  border-radius: 6px;
  color: #033c4a;
  margin: 0px 32px 24px 32px;
  margin-top: -10px;
}

.note {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.24px;
}

.infoIcon {
  width: 18px;
  height: 18px;
}

@media only screen and (max-width: 767px) {
  .disclaimer {
    margin-top: unset;
    margin: 16px 12px 16px 12px;
  }
}
