.cardWrapper {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #e8e5f6;
  background: linear-gradient(
    104deg,
    #f3f2fb 23.87%,
    #f9f8fa 49.29%,
    #f3f2fb 74.72%
  );
  gap: 12px;
  padding: 8px;
  display: flex;
  align-items: center;
  height: 58px;
}

.profileImg {
  width: 40px;
  height: 40px;
  border-radius: 4px;
}

.name {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 100%;
}
.link {
  color: #493ab1;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
}

.nameWrapper {
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: calc(100% - 52px);
}
