.chipWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: #00b779;
  padding: 3px 6px;
}

.text {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.24px;
}
