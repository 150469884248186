.date {
  color: rgba(39, 37, 34, 0.5);
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.28px;
  white-space: nowrap;
}

.wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}
