.lockIcon {
  fill: rgba(255, 170, 0, 1) !important;
  font-size: 48px !important;
}

.title {
  font-weight: 500;
  font-size: 18px;
}

.description {
  font-weight: 500;
  font-size: 14px;
  color: rgba(39, 37, 34, 1);
  opacity: 0.5;
}

.ol {
  padding-left: 20px;
}
