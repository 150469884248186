.chip {
  padding: 5px 10px;
  border-radius: 5px;
  color: rgba(51, 34, 0, 1);
}

.label {
  font-weight: 500;
  font-size: 12px;
  fill: rgba(51, 34, 0, 1) !important;
}

.desktopModalPaper {
  min-width: 480px !important;
  max-width: 480px !important;
}

.auto_high {
  width: 14px;
  height: 14px;
  fill: white !important;
}

.ctaText {
  color: white;
}
