.menuWrapper {
  width: 276px;
  border-right: 1.5px solid #e8e8e8;
  padding: 18px 0px;
  height: calc(100dvh - 60px);
}

.sectionWrapper {
  height: calc(100% - 76px);
  overflow: auto;
  margin-bottom: 18px;
}

.sectionTitle {
  color: rgba(39, 37, 34, 0.5);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 0px 16px;
}

.footer {
  padding: 0px 10px;
}

.sectionContainer {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 767px) {
  .menuWrapper {
    width: 100%;
  }
}
