.content {
  color: rgba(39, 37, 34, 0.85);
  font-family: "Helvetica Neue";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px; /* 135.714% */
  letter-spacing: 0.28px;
}

.contentWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 18px;
}

.modalPaper {
  width: 429px;
}
.secondaryBtn {
  color: #493ab1 !important;
  min-width: unset !important;
}

.footer {
  justify-content: space-between !important;
  padding: 16px !important;
  height: unset !important;
}
