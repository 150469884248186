.hide {
  display: none;
}

.childrenComponentStyle {
  padding: 40px 48px;
  width: 100%;
  height: calc(100dvh - 60px);
  overflow: auto;
}

@media only screen and (max-width: 768px) {
  .childrenComponentStyle {
    padding: unset;
    height: 100dvh;
    background: #eee;
  }
}
